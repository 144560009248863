<template>
  <div>
    <px-capa :banner-capa="header"
             btn-text="Quero comprar agora"
             :external-link="linkCapaBtn" target="_blank">
      <template v-slot:title>
        Óleos Hidráulicos para sua empresa
      </template>
      <template v-slot:text>
        Entre em contato e compre os produtos que sua empresa precisa
      </template>
    </px-capa>
    <v-container class="mkt-content-container mt-5">
      <px-home-page-block
        show-link
        link="/q/c/215"
        class="mt-5"
        :query="query"
        title="Conheça nossos produtos"
        :qtd-ofertas="20"/>
      <px-landing-page-whatsapp class="mt-5 mb-12"/>
      <px-landing-page-banners class="mt-12"
                               :banners="banners"/>
      <px-vantagens class="mt-12 mb-12"/>
      <px-banner-cadastro-email class="mt-5 mb-12"
                                :is-link="false"
                                :text="bannerCadastroText"
                                @click="onClickBannerCadastro"/>
      <px-como-funciona class="mt-12 mb-12"/>
      <px-faq-landing-page class="mt-12 mb-10"/>
      <div class="headline font-weight-bold ml-2 mb-5">
        Precisa falar conosco?
      </div>
      <v-row class="mt-10">
        <v-col cols="12">
          <px-rd-duvida-form-subscription/>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import gridMixin from '@/mixins/grid-mixin';
import HomePageBlock from '@/modules/home-page/HomePageBlock.vue';
import LandingPageBanners from '@/modules/landing-page/LandingPageBanners.vue';
import Capa from '@/modules/landing-page/Capa.vue';
import Vantagens from '@/modules/institucional/Vantagens.vue';
import ComoFunciona from '@/modules/institucional/ComoFunciona.vue';
import FaqLandingPage from '@/modules/landing-page/FaqLandingPage.vue';
import RdDuvidaFormSubscription from '@/modules/integration/rd/RdDuvidaFormSubscription.vue';
import PxBannerCadastroEmail from '@/components/marketing/PxBannerCadastroEmail.vue';
import LandingPageWhatsapp from './LandingPageWhatsapp.vue';
import header from '../../assets/landing-page/oleos-hidraulicos/header-oleo-hidraulico.png';
import promoOleoFox from '../../assets/landing-page/oleos-hidraulicos/68-fox.png';
import promoOleoFoxMobile from '../../assets/landing-page/oleos-hidraulicos/68-fox-mobile.png';
import promoHydra from '../../assets/landing-page/oleos-hidraulicos/hydra.png';
import promoHydraMonile from '../../assets/landing-page/oleos-hidraulicos/hydra-mobile.png';
import promoHydrocer from '../../assets/landing-page/oleos-hidraulicos/hydrocer.png';
import promoHydrocerMobile from '../../assets/landing-page/oleos-hidraulicos/hydrocer-mobile.png';

export default {
  mixins: [gridMixin],
  data() {
    return {
      header,
      query: 'disponivel is true and categoria.id = 215 order by random',
      bannerCadastroText: 'Cadastre-se agora e começe a comprar os materiais de escritório para sua empresa',
      banners: [],
      linkCapaBtn: 'https://api.whatsapp.com/send?phone=554891075464&text=Ol%C3%A1!%20Quero%20saber%20mais%20sobre%20os%20produtos%20da%20Proxpect!',
    };
  },
  computed: {
    height() {
      if (this.isMobile) {
        return '300px';
      }
      return '360px';
    },
  },
  methods: {
    onClickBannerCadastro() {
      this.$router.push({
        name: 'public.criar-conta',
      });
    },
  },
  created() {
    this.banners = [
      {
        foto: this.getGridImg(promoOleoFox, promoOleoFoxMobile),
        link: '/oferta/42386',
      },
      {
        foto: this.getGridImg(promoHydra, promoHydraMonile),
        link: '/oferta/6608',
      },
      {
        foto: this.getGridImg(promoHydrocer, promoHydrocerMobile),
        link: '/oferta/18185',
      },
    ];
  },
  components: {
    pxHomePageBlock: HomePageBlock,
    pxLandingPageBanners: LandingPageBanners,
    pxCapa: Capa,
    pxVantagens: Vantagens,
    pxComoFunciona: ComoFunciona,
    pxFaqLandingPage: FaqLandingPage,
    pxRdDuvidaFormSubscription: RdDuvidaFormSubscription,
    PxBannerCadastroEmail,
    pxLandingPageWhatsapp: LandingPageWhatsapp,
  },
};
</script>
